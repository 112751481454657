<!-- Hero Start -->

<section class="bg-half-170 d-table w-100  overflow-hidden" id="home" style="padding-bottom: 20px !important; padding-top: 100px;">
   

    <div class="contenedor">
        <img src="assets/images/fisioterapia-bilbao/01_Fisioterapeuta_bilbao_Portada.webp" alt="Imagen de fondo" class="image">
        <div class="overlay">
          <h2 class="headline">Centro MÉDICOS BILBAO</h2>
          <h1 class="headline">CENTRO DE FISIOTERAPIA EN BILBAO</h1>
        </div>
      </div>      
    <div class="container">
        <div class="row mt-5 align-items-center">
            <div class="col-md-12">
                <div class="title-heading">                    
                    <p>En nuestra consulta de fisioterapia en Bilbao, te ofrecemos un tratamiento personalizado para aliviar tu dolor y mejorar tu calidad de vida.</p>
                    
                    <p>Creemos en un enfoque integral de la fisioterapia, centrado en las necesidades individuales de cada paciente. Gracias a nuestro fisioterapeuta en Bilbao, según sea tu caso, te ayudaremos a recuperar tu movilidad, aliviar tu dolor, mejorar tu calidad de vida, volver a tu máximo potencial de rendimiento deportivo, o prevenir lesiones.</p>                    
                    
                    <h3 class="text-center">FISIOTERAPEUTA EN BILBAO</h3>
                    <p>En <strong><a href="https://medicosbilbao.com" target="_blank">MÉDICOS BILBAO</a></strong> nos apasiona el cuidado de su salud física, y Anton Azlor, nuestro fisioterapeuta en Bilbao, experto en rehabilitación y lesiones deportivas dirige nuestra consulta de fisioterapia en Bilbao con un enfoque personalizado del paciente, comprendiendo primero las necesidades individuales de cada uno de nuestros pacientes y elaborando los tratamientos y planificando las sesiones adaptándose a cada caso. </p>                       
                                        
                        <div class="flex-container">
                            <img src="assets/images/fisioterapia-bilbao/logo_colegio_fisios.webp" 
                                 loading="lazy" 
                                 class="img-fluid img-left" 
                                 alt="Colegios Oficial de Fisioterapeutas del País Vasco">
                            <p class="lead">
                                <strong>Médicos Bilbao</strong> es un Centro reconocido por el 
                                <strong>Colegio Oficial de Fisioterapeutas del País Vasco</strong>
                            </p>
                        </div>                    
                </div>
            </div>           
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Start -->
<section class="section bg-light" style="padding-top: 20px !important; padding-bottom:20px !important;">
    <div class="container">
        <div class="row">           

            <div class="col-lg-3 col-md-6 col-6 mt-4 mt-lg-0 pt-2 pt-lg-0">
                <div class="card features feature-primary feature-full-bg rounded p-4 position-relative overflow-hidden border-0">
                   
                    <div class="card-body p-0 content">
                        <h5>Centro <br> Médicos Bilbao</h5>

                        <a href="https://medicosbilbao.com/"  target="_blank" class="btn btn-pills btn-soft-success mt-4">Ir a la web</a>         
                        
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-3 col-md-6 col-6 mt-4 mt-lg-0 pt-2 pt-lg-0">
                <div class="card features feature-primary feature-full-bg rounded p-4 position-relative overflow-hidden border-0">
                   
                    <div class="card-body p-0 content">
                        <h5>Nuestro <br>Fisioterapeuta</h5>                        

                        <a href="https://medicosbilbao.com/quienes-somos/anton-azlor-fisioterapeuta-bilbao" target="_blank" class="btn btn-pills btn-soft-success mt-4">Antón Azlor</a>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-3 col-md-6 col-6 mt-4 mt-lg-0 pt-2 pt-lg-0">
                <div class="card features feature-primary feature-full-bg rounded p-4 position-relative overflow-hidden border-0">
                    
                    <div class="card-body p-0 content">
                        <h5>Dónde<br> Estamos</h5>
                              <a href="https://medicosbilbao.com/contactar" target="_blank" class="btn btn-pills btn-soft-success mt-4">Ver Mapa</a>
                    </div>
                </div>
            </div>
            <!--end col-->
            <div class="col-lg-3 col-md-6 col-6 mt-4 mt-lg-0 pt-2 pt-lg-0">
                <div class="card features feature-primary feature-full-bg rounded p-4 position-relative overflow-hidden border-0">
                   
                    <div class="card-body p-0 content">
                        <h5>Reservar <br>Cita</h5>  
                            <a href="https://medicosbilbao.com/contactar" class="btn btn-pills btn-soft-success mt-4" data-bs-toggle="modal"
                            data-bs-target="#appointment-request">Ir a contactar</a>                       
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- End -->

<section class="section" style="padding-top: 20px ; padding-bottom:20px ;">

    <div class="container ">
        <h4 class="title mb-4 text-center">NUESTRA CONSULTA DE FISIOTERAPIA EN BILBAO</h4>

        <p class="text-center lead">Nuestra consulta de <strong>Fisioterapia en Bilbao</strong> está ubicada en plena Gran Vía Don Diego López de Haro 81, con un acceso inmejorable, cerca del metro, tranvía, autobús, parada de taxi y parking público. El trato cercano y personalizado de nuestro experimentado equipo así como poder contar con unas modernas y cómodas instalaciones son la base para que te sientas como en casa cuando nos visites.</p>
      
                          <div class="row">
                              <div class="col-lg-6 order-2 order-lg-1 p-0">
                                  <div class="h-100 m-0">
                                      <div class="row m-0">
                                          <div class="col-half-section col-half-section-right py-2 ms-auto">
                                              <div class="p-3">
                                                  <h3 class="font-weight-semibold ls-0 text-color-default text-4 mb-0">Fisioterapia en Bilbao</h3>
                                                  
                                                  <p class="text-3-5 pb-2 text-justify"      >
                                                      <p>Nuestro <strong>fisioterapeuta en Bilbao</strong> te ayuda a aliviar el dolor, recuperar la movilidad y prevenir lesiones, bien porque sufras dolor de espalda, problemas posturales, lesiones musculares o articulares, o simplemente desees mejorar tu estado físico en actividades cotidianas.<p>
                                                            <p>
                                                                Mediante técnicas como la terapia manual, los ejercicios terapéuticos y la electroterapia, en nuestra consulta de fisioterapia en Bilbao te ayudaremos a alcanzar tus objetivos de forma personalizada y segura.
                                                        </p>                    
                                                  
                                                  <a href="https://medicosbilbao.com/especialidades/fisioterapeuta-bilbao" class="btn border rounded-0 px-5 btn-py-3 font-weight-bold positive-ls-2 text-color-dark bg-color-hover-primary text-color-hover-light appear-animation" data-appear-animation="fadeInUpShorterPlus" data-appear-animation-delay="600" data-plugin-options="{'minWindowWidth': 0}">Más Info</a>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div class="col-lg-6 order-1 order-lg-2 p-0">
                                  
                                  <img src="assets/images/fisioterapia-bilbao/02_Fisioterapia_bilbao_Portada_2.webp" class="img-fluid" alt="Fisioterapeuta en Bilbao" loading="lazy">
                                 
                              </div>
                          </div>	
      
                          <div class="row" >                    
                              <div class="col-lg-6 p-0">
                                 
                                  <img src="assets/images/fisioterapia-bilbao/03_Fisioterapeuta_bilbao_lesion_deportiva.webp" class="img-fluid" alt="Fisioterapeuta Deportivo" loading="lazy">
      
                              </div>
                              <div class="col-lg-6 p-0">
                                  <div class="h-100 m-0">
                                      <div class="row m-0">
                                          <div class="col-half-section col-half-section-left py-2">
                                              <div class="p-3">
                                                  <h3 class="font-weight-semibold ls-0 text-color-default text-4 mb-0   ">Fisioterapia Deportiva en Bilbao</h3>
                                                  
                                                  <p class="text-3-5 pb-2 text-justify"     > <p>Nuestro <strong>fisioterapeuta deportivo en Bilbao</strong> te acompaña en cada paso, desde la prevención de lesiones hasta la recuperación de las mismas. Te ayuda a optimizar tu rendimiento, mejorar tu técnica y prevenir futuras molestias. </p><p>
                                                    Gracias a nuestra consulta de fisioterapia deportiva en Bilbao, podrás alcanzar tu máximo potencial, reducir el riesgo de lesiones, acortar tus tiempos de recuperación tras lesiones, y gestionar el dolor, aliviando el dolor muscular y articular para que puedas seguir entrenando y compitiendo sin molestias.
                                                    </p>
                  
                                                  <a href="https://medicosbilbao.com/especialidades/fisioterapeuta-deportivo-bilbao" class="btn border rounded-0 px-5 btn-py-3 font-weight-bold positive-ls-2 text-color-dark bg-color-hover-primary text-color-hover-light appear-animation" data-appear-animation="fadeInUpShorterPlus" data-appear-animation-delay="600" data-plugin-options="{'minWindowWidth': 0}">Más Info</a>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>     
                      </div>
    

    <div class="container mt-20">
        <div class="row align-items-end mb-4 pb-2">
            <div class="col-md-10">
                <div class="section-title  text-md-start">
                    
                  
                   <h2 class="text-center ">QUÉ TRATAMOS EN NUESTRA CONSULTA DE FISIOTERAPIA EN BILBAO</h2>

                 
                </div>
            </div>
            <!--end col-->

           
            <!--end col-->
        </div>
        <!--end row-->


        <div class="row"> 
            <div >
                <ul ngbNav #nav="ngbNav" [(activeId)]="active"
                    class="nav nav-pills rounded shadow p-3 mb-0 col-md-12 list-inline  list-inline-columns" orientation="horizontal">
                    <li ngbNavItem="top" class="nav-item list-inline-item col-6 col-md-4">
                        <a ngbNavLink>
                            <div class="text-start py-1 px-2">
                                <h6 class="mb-0">Dolor de Espalda</h6>
                            </div>
                        </a>
                        <ng-template ngbNavContent>
                            <div class="tab-pane fade p-4 rounded shadow active show" id="dental"
                                role="tabpanel" aria-labelledby="fisio">
                                <img src="assets/images/fisioterapia-bilbao/04_Fisioterapia_Bilbao_Dolor_espalda.webp" class="rounded shadow" style="width:100%" loading="lazy" alt="Dolor de Espalda">
                                <div class="mt-4">
                                    <h5>Dolor de Espalda</h5>
                                    <p class="text-muted my-3"><strong>Nuestro fisioterapeuta en Bilbao elaborará un tratamiento individualizado para evaluar y tratar de forma adaptada a la causa y gravedad de tu dolor de espalda.</strong></p>
    
                                    <h3>Causas del dolor de espalda:</h3>
                                    <ul>
                                        <li>Distensiones y espasmos musculares</li>
                                        <li>Hernias discales</li>
                                        <li>Artritis</li>
                                        <li>Mala postura</li>
                                        <li>Sedentarismo</li>
                                        <li>Sobrepeso</li>
                                    </ul>
                                    
                                    <h3>Tratamiento del dolor de espalda:</h3>
                                    <ul>
                                        <li><strong>Terapia manual:</strong> Masaje, movilizaciones articulares, estiramientos</li>
                                        <li><strong>Ejercicios de fortalecimiento y flexibilidad:</strong> Para mejorar la postura y la estabilidad de la columna vertebral</li>
                                        <li><strong>Electroterapia:</strong> TENS, ultrasonido, magnetoterapia</li>
                                        <li><strong>Vendaje neuromuscular:</strong> Para aliviar el dolor y mejorar la propiocepción</li>
                                        <li><strong>Educación postural:</strong> Para corregir hábitos posturales inadecuados</li>
                                        <li>En algunos casos, el fisioterapeuta puede recomendar otras terapias complementarias, como acupuntura o terapia cognitivo-conductual.</li>
                                    </ul>
                             
                                     
                                </div>
                            </div>
                        </ng-template>
                    </li>
                    
                    <li ngbNavItem="middle" class="nav-item  list-inline-item col-6 col-md-4">
                        <a ngbNavLink>
                            <div class="text-start py-1 px-2">
                                <h6 class="mb-0">Dolor de cuello</h6>
                            </div>
                        </a>
                        <ng-template ngbNavContent>
                            <div class="tab-pane fade p-4 rounded shadow active show" id="dental"
                                role="tabpanel" aria-labelledby="fisio">
                                <img src="assets/images/fisioterapia-bilbao/05_Fisioterapeuta_bilbao_Dolor_cuello.webp" class="rounded shadow" style="width:100%" loading="lazy" alt="Dolor de cuello">
                                <div class="mt-4">
                                    <h5>Dolor de cuello</h5>
                                   

                                    <p class="text-muted my-3"><strong>Junto con el dolor de cabeza y el articular es una de las dolencias más frecuentes en cualquier edad que es tratada por un fisioterapeuta. En nuestra consulta de fisioterapia en Bilbao, evaluaremos tu caso y tendrás un tratamiento personalizado para mejorar tu dolor de cuello.</strong></p>
    
                                    <h3 >Causas del dolor de cuello:</h3>
                                    <ul >
                                        <li>Mala postura: Prolongar horas en posiciones inadecuadas, como encorvarse sobre el ordenador o el teléfono, puede tensionar los músculos del cuello.</li>
                                        <li>Lesiones: Latigazo cervical, golpes directos o movimientos bruscos pueden provocar dolor e inflamación en la zona cervical.</li>
                                        <li>Artritis: La artrosis cervical es una enfermedad degenerativa que causa desgaste y rigidez en las articulaciones del cuello.</li>
                                        <li>Estrés y tensión: El estrés emocional puede generar contracturas musculares que causan dolor en el cuello.</li>
                                        <li>Condiciones neurológicas: Algunas enfermedades neurológicas pueden afectar los nervios del cuello, provocando dolor y alteraciones de la sensibilidad.</li>
                                    </ul>
                                    
                                    <h3 >Tratamiento del dolor de cuello:</h3>
                                    <ul >
                                        <li><strong>Terapia manual:</strong> Masaje, movilizaciones articulares y estiramientos para aliviar la tensión muscular, mejorar la movilidad y reducir el dolor.</li>
                                        <li><strong>Ejercicios de fortalecimiento y flexibilidad:</strong> Fortalecer los músculos del cuello y mejorar la flexibilidad de la columna vertebral para prevenir futuras lesiones y mejorar la postura.</li>
                                        <li><strong>Electroterapia:</strong> TENS, ultrasonido o magnetoterapia para reducir el dolor y la inflamación.</li>
                                        <li><strong>Vendaje neuromuscular:</strong> Aplicación de vendajes para brindar soporte a la zona cervical, aliviar el dolor y mejorar la propiocepción.</li>
                                        <li><strong>Educación postural:</strong> Consejos y técnicas para corregir hábitos posturales inadecuados que contribuyen al dolor de cuello.</li>
                                    </ul>                                     
                                </div>
                            </div>
                        </ng-template>
                    </li>
                    <li ngbNavItem="bottom" class="nav-item  list-inline-item col-6 col-md-4">
                        <a ngbNavLink>
                            <div class="text-start py-1 px-2">
                                <h6 class="mb-0">Dolor articular</h6>
                            </div>
                        </a>
                        <ng-template ngbNavContent>
                            <div class="tab-pane fade p-4 rounded shadow  show" id="dental"
                                role="tabpanel" aria-labelledby="fisio">
                                <img src="assets/images/fisioterapia-bilbao/06_Fisioterapia_bilbao_Dolor_articulación.webp" class="rounded shadow"  style="width:100%" loading="lazy" alt="Dolor articular">
                                <div class="mt-4">
                                    <h5>Dolor articular</h5>
                                    
                                        <p class="text-muted my-3"><strong>El dolor articular es muy frecuente, al igual que el dolor de cabeza y de cuello, siendo una de las dolencias más evaluadas y tratadas. Consulta a nuestro fisioterapeuta en Bilbao para tratar tus dolores articulares.</strong></p>
    
                                        <h3>Causas del dolor articular:</h3>
                                        <ul>
                                            <li>Artritis: Osteoartritis, artritis reumatoide, artritis psoriásica, gota.</li>
                                            <li>Lesiones: Esguinces, torceduras, roturas de ligamentos, fracturas.</li>
                                            <li>Uso excesivo: Movimientos repetitivos, actividades de alto impacto.</li>
                                            <li>Enfermedades autoinmunes: Lupus, artritis reumatoide.</li>
                                            <li>Envejecimiento: Desgaste natural de las articulaciones.</li>
                                        </ul>
                                        
                                        <h3>Tratamiento del dolor articular:</h3>
                                        <ul>
                                            <li><strong>Terapia manual:</strong> Masaje, movilizaciones articulares, estiramientos.</li>
                                            <li><strong>Ejercicios de fortalecimiento y flexibilidad:</strong> Para mejorar la movilidad y la estabilidad de la articulación afectada.</li>
                                            <li><strong>Electroterapia:</strong> TENS, ultrasonido, magnetoterapia.</li>
                                            <li><strong>Vendaje neuromuscular:</strong> Para aliviar el dolor y mejorar la propiocepción.</li>
                                            <li><strong>Crioterapia y termoterapia:</strong> Para reducir la inflamación y el dolor.</li>
                                            <li><strong>Educación postural y ergonomía:</strong> Para prevenir futuras lesiones.</li>
                                        </ul>                                     
                                </div>
                            </div>
                        </ng-template>
                    </li>
                    <li ngbNavItem="general" class="nav-item  list-inline-item col-6 col-md-4">
                        <a ngbNavLink>
                            <div class="text-start py-1 px-2">
                                <h6 class="mb-0">Lesiones deportivas</h6>
                            </div>
                        </a>
                        <ng-template ngbNavContent>
                            <div class="tab-pane fade p-4 rounded shadow active show" id="dental"
                                role="tabpanel" aria-labelledby="fisio">
                                <img src="assets/images/fisioterapia-bilbao/07_Fisioterapeuta_Deportivo_Bilbao.webp" class="rounded shadow" style="width:100%" loading="lazy" alt="Lesiones deportivas">
                                <div class="mt-4">
                                    <h5>Lesiones deportivas</h5>
                                    
                                                                        
                                        <p class="text-muted my-3"><strong>Las lesiones deportivas son comunes entre atletas y personas activas. Los fisioterapeutas juegan un papel crucial en la recuperación y el regreso seguro a la actividad.</strong></p>
    
                                        <h3>Causas de las lesiones deportivas:</h3>
                                        <ul>
                                            <li>Movimientos repetitivos: Sobrecarga muscular y tendinosa por técnica inadecuada o entrenamiento excesivo.</li>
                                            <li>Traumatismos: Golpes, caídas o choques que provocan daño a músculos, ligamentos, huesos o articulaciones.</li>
                                            <li>Falta de calentamiento y estiramiento: Inadecuada preparación muscular antes de la actividad.</li>
                                            <li>Desequilibrios musculares: Debilidad o falta de coordinación en grupos musculares antagonistas.</li>
                                            <li>Falta de acondicionamiento físico: Debilidad muscular, mala flexibilidad, desequilibrios musculares.</li>
                                            <li>Condiciones preexistentes: Enfermedades crónicas, problemas posturales, lesiones antiguas mal curadas.</li>
                                        </ul>
                                        
                                        <h3>Tratamiento de lesiones deportivas:</h3>
                                        <ul>
                                            <li><strong>Evaluación:</strong> El fisioterapeuta analiza la lesión, su gravedad y causa para diseñar un plan personalizado.</li>
                                            <li><strong>Terapia manual:</strong> Técnicas como masaje, movilizaciones y manipulaciones para reducir el dolor, la inflamación y mejorar la movilidad.</li>
                                            <li><strong>Ejercicios:</strong> Fortalecimiento, propiocepción, equilibrio y flexibilidad para recuperar la función muscular y prevenir relesiones.</li>
                                            <li><strong>Electroterapia:</strong> TENS, ultrasonido o magnetoterapia para aliviar el dolor y la inflamación.</li>
                                            <li><strong>Vendaje neuromuscular:</strong> Soporte y propiocepción para la zona lesionada.</li>
                                            <li><strong>Reeducación postural y propiocepción:</strong> Mejora la postura y la estabilidad articular para prevenir futuras lesiones.</li>
                                        </ul>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                    <li ngbNavItem="medicine" class="nav-item  list-inline-item col-6 col-md-4">
                        <a ngbNavLink>
                            <div class="text-start py-1 px-2">
                                <h6 class="mb-0">Problemas de equilibrio</h6>
                            </div>
                        </a>
                        <ng-template ngbNavContent>
                            <div class="tab-pane fade p-4 rounded shadow active show" id="dental"
                                role="tabpanel" aria-labelledby="fisio">
                                <img src="assets/images/fisioterapia-bilbao/08_Fisioterapia_Bilbao_equilibrio.webp" class="rounded shadow" style="width:100%" loading="lazy" alt="Problemas de equilibrio"> 
                                <div class="mt-4">
                                    <h5>Problemas de equilibrio</h5>
                                    
                                        <h3>Causas de los problemas de equilibrio:</h3>
                                        <ul>
                                            <li>Envejecimiento: Los cambios en el sistema vestibular y en la propiocepción pueden afectar el equilibrio a medida que envejecemos.</li>
                                            <li>Lesiones: Las lesiones en la cabeza, el cuello o la espalda pueden afectar el equilibrio.</li>
                                            <li>Enfermedades neurológicas: Enfermedades como el ictus, la enfermedad de Parkinson o la esclerosis múltiple pueden afectar el equilibrio.</li>
                                            <li>Problemas de visión: La mala visión puede dificultar la percepción del entorno y contribuir a problemas de equilibrio.</li>
                                            <li>Medicamentos: Algunos medicamentos pueden afectar el equilibrio como efecto secundario.</li>
                                        </ul>
                                        
                                        <h3>Tratamiento fisioterapéutico:</h3>
                                        <ul>
                                            <li><strong>Terapia manual:</strong> Técnicas para mejorar la movilidad articular y reducir la tensión muscular que puede afectar el equilibrio.</li>
                                            <li><strong>Ejercicios de propiocepción:</strong> Mejoran la capacidad del cuerpo para percibir su posición en el espacio.</li>
                                        
                                            <li><strong>Entrenamiento del equilibrio:</strong> Ayudan a mejorar la coordinación y la estabilidad.</li>
                                            <li><strong>Terapia vestibular:</strong> Se utiliza para tratar problemas relacionados con el sistema vestibular, que es responsable del equilibrio.</li>
                                            <li><strong>Fortalecimiento muscular:</strong> Fortalecer los músculos de las piernas y el tronco puede mejorar el equilibrio.</li>
                                            <li><strong>Adaptación del entorno:</strong> Se pueden recomendar cambios en el entorno del hogar para reducir el riesgo de caídas.</li>
                                        </ul>                                        
                                </div>
                            </div>
                        </ng-template>
                    </li>
                    <li ngbNavItem="orthopadic" class="nav-item  list-inline-item col-6 col-md-4">
                        <a ngbNavLink>
                            <div class="text-start py-1 px-2">
                                <h6 class="mb-0">Enfermedades neurológicas</h6>
                            </div>
                        </a>
                        <ng-template ngbNavContent>
                            <div class="tab-pane fade p-4 rounded shadow active show" id="dental"
                                role="tabpanel" aria-labelledby="fisio">
                                <img src="assets/images/fisioterapia-bilbao/09_Fisioterapeuta_bilbao_rehabilitación_neurologica.webp" class="rounded shadow"  style="width:100%" loading="lazy" alt="Enfermedades neurológicas"> 
                                <div class="mt-4">
                                    <h5>Problemas físicos por Enfermedades neurológicas</h5>
                                    
                                    <p class="text-muted my-3">Los fisioterapeutas juegan un papel crucial en el tratamiento de diversos problemas físicos originados por condiciones neurológicas, como:</p>
    
                                        <h3>Causas de las condiciones neurológicas:</h3>
                                        <ul>
                                            <li>Accidentes cerebrovasculares</li>
                                            <li>Lesiones medulares</li>
                                            <li>Enfermedades neurodegenerativas (Parkinson, esclerosis múltiple)</li>
                                            <li>Tumores cerebrales</li>
                                            <li>Trastornos motores</li>
                                            <li>Infecciones del sistema nervioso</li>
                                        </ul>
                                        
                                        <h3>Tratamiento de las condiciones neurológicas:</h3>
                                        <ul>
                                            <li><strong>Terapia manual:</strong> Mejora la movilidad articular, la propiocepción y la coordinación.</li>
                                            <li><strong>Ejercicios:</strong> Fortalece los músculos, mejora el equilibrio y la marcha.</li>
                                            <li><strong>Electroterapia:</strong> Reduce el dolor y la espasticidad muscular.</li>
                                            <li><strong>Vendaje neuromuscular:</strong> Brinda soporte y facilita el movimiento.</li>
                                            <li><strong>Tecnologías de rehabilitación:</strong> Estimulan el movimiento y la función neurológica.</li>
                                        </ul>
                                        
                                        <h3>Objetivos del tratamiento:</h3>
                                        <ul>
                                            <li>Recuperar la movilidad y la función muscular.</li>
                                            <li>Mejorar el equilibrio y la coordinación.</li>
                                            <li>Reducir el dolor y la espasticidad.</li>
                                            <li>Aumentar la independencia funcional.</li>
                                            <li>Mejorar la calidad de vida.</li>
                                        </ul>

                                     
                                </div>
                            </div>
                        </ng-template>
                    </li>   
                    
                    <li ngbNavItem="cronico" class="nav-item  list-inline-item col-6 col-md-4">
                        <a ngbNavLink>
                            <div class="text-start py-1 px-2">
                                <h6 class="mb-0">Dolor crónico</h6>
                            </div>
                        </a>
                        <ng-template ngbNavContent>
                            <div class="tab-pane fade p-4 rounded shadow active show" id="dental"
                                role="tabpanel" aria-labelledby="fisio">
                                <img src="assets/images/fisioterapia-bilbao/10_Fisioterapia_bilbao_dolor_cronico.webp" class="rounded shadow"  style="width:100%" loading="lazy" alt="Dolor crónico"> 
                                <div class="mt-4">
                                    <h5>Dolor crónico</h5>                                    

                                        <p class="text-muted my-3">Los fisioterapeutas evalúan la causa del dolor crónico, ya sea muscular, articular, neurológico o una combinación de factores. Nuestro fisioterapeuta en Bilbao evaluará y diseñará un plan de tratamiento individualizado para conseguir una gestión del dolor y mejorar tu calidad de vida.</p>
    
    <h3>Causas del dolor crónico pueden ser, entre otras:</h3>
    <ul>
        <li>Lesiones: Fracturas, esguinces, desgarros musculares, etc.</li>
        <li>Enfermedades: Artritis, osteoporosis, fibromialgia, etc.</li>
        <li>Condiciones neurológicas: Dolor neuropático por diabetes, lesión de la médula espinal, etc.</li>
        <li>Factores psicológicos: Estrés, ansiedad, depresión.</li>
        <li>Factores ocupacionales: Movimientos repetitivos, posturas inadecuadas.</li>
    </ul>
    
    <h3>Tratamientos del dolor crónico:</h3>
    <ul>
        <li><strong>Terapia manual:</strong> Técnicas como el masaje, la movilización articular y la terapia miofascial liberan tensiones musculares y mejoran la movilidad.</li>
        <li><strong>Ejercicios terapéuticos:</strong> Fortalecen los músculos, mejoran la flexibilidad, el equilibrio y la propiocepción, reduciendo el dolor y mejorando la función.</li>
        <li><strong>Electroterapia:</strong> Estimula los nervios y músculos para aliviar el dolor, la inflamación y la espasticidad muscular.</li>
        <li><strong>Hidroterapia:</strong> Ejercicios en agua disminuyen el impacto articular, facilitando el movimiento y reduciendo el dolor.</li>
        <li><strong>Termoterapia:</strong> Calor o frío para aliviar el dolor, la inflamación y la espasticidad muscular.</li>
        <li><strong>Técnicas de relajación:</strong> Respiración profunda, mindfulness y biofeedback para reducir el estrés y la ansiedad que intensifican el dolor.</li>
        <li><strong>Educación:</strong> El fisioterapeuta educa al paciente sobre su condición, el dolor y las estrategias de automanejo para mejorar su calidad de vida.</li>
    </ul>
    
    <h3>Beneficios de la fisioterapia para el dolor crónico:</h3>
    <ul>
        <li>Reducción del dolor y la inflamación.</li>
        <li>Mejora de la movilidad y la función física.</li>
        <li>Disminución del uso de medicamentos para el dolor.</li>
        <li>Mejora del estado de ánimo y la calidad de vida.</li>
        <li>Prevención de la discapacidad.</li>
    </ul>
                                     
                                </div>
                            </div>
                        </ng-template>
                    </li>   

                    <li ngbNavItem="articulaciones" class="nav-item  list-inline-item col-6 col-md-4">
                        <a ngbNavLink>
                            <div class="text-start py-1 px-2">
                                <h6 class="mb-0">Lesiones en articulaciones</h6>
                            </div>
                        </a>
                        <ng-template ngbNavContent>
                            <div class="tab-pane fade p-4 rounded shadow active show" id="dental"
                                role="tabpanel" aria-labelledby="fisio">
                                <img src="assets/images/fisioterapia-bilbao/11_Fisioterapia_Bilbao_Lesion_articulaciones.webp" class="rounded shadow"  style="width:100%" loading="lazy" alt="Lesiones típicas en articulaciones"> 
                                <div class="mt-4">
                                    <h5>Lesiones típicas en articulaciones</h5>
                                                                            

                                        <p class="text-muted my-3">En nuestro centro de fisioterapia en Bilbao, nuestro fisioterapeuta evalúa y trata de forma personalizada, de acuerdo a un programa específico de recuperación, las siguientes lesiones:</p>
    
                                        <h3>Lesiones de articulaciones que tratamos en la consulta de fisioterapia en Bilbao:</h3>
                                        <ul>
                                            <li><strong>Tobillo:</strong> Esguinces, luxaciones, fracturas, tendinitis, bursitis.</li>
                                            <li><strong>Rodilla:</strong> Esguinces de ligamentos (cruzado anterior, medial, lateral), meniscopatías, tendinitis rotuliana, condromalacia rotuliana.</li>
                                            <li><strong>Hombro:</strong> Tendinitis del manguito rotador, inestabilidad articular, luxaciones, bursitis subacromial.</li>
                                            <li><strong>Espalda baja:</strong> Lumbalgia aguda o crónica, hernias discales, facetartropatía, ciática.</li>
                                            <li><strong>Cuello:</strong> Cervicalgia aguda o crónica, latigazo cervical, artrosis cervical.</li>
                                        </ul>

                                     
                                </div>
                            </div>
                        </ng-template>
                    </li>   

                    <li ngbNavItem="musculares" class="nav-item  list-inline-item col-6 col-md-4">
                        <a ngbNavLink>
                            <div class="text-start py-1 px-2">
                                <h6 class="mb-0">Lesiones musculares</h6>
                            </div>
                        </a>
                        <ng-template ngbNavContent>
                            <div class="tab-pane fade p-4 rounded shadow active show" id="dental"
                                role="tabpanel" aria-labelledby="fisio">
                                <img src="assets/images/fisioterapia-bilbao/12_FISIOTERAPIA_BILBAO_LESION_MUSCULAR.webp" class="rounded shadow"  style="width:100%" loading="lazy" alt="Lesiones típicas en músculos"> 
                                <div class="mt-4">
                                    <h5>Lesiones típicas en músculos</h5>
                                    <p class="text-muted my-3">
                                        Nuestro fisioterapeuta en Bilbao tratará de forma personalizada tu lesión para ofrecerte una recuperación rápida y lograr el mejor nivel de recuperación física posible.</p>
    
                                        <h3>Lesiones de músculos que tratamos en la consulta de fisioterapia en Bilbao:</h3>
                                        <ul>
                                            <li><strong>Isquiotibiales:</strong> Desgarros, contracturas, tendinitis.</li>
                                            <li><strong>Cuádriceps:</strong> Desgarros, contracturas.</li>
                                            <li><strong>Gemelos:</strong> Desgarros, contracturas, tendinitis.</li>
                                            <li><strong>Músculos aductores de la cadera:</strong> Desgarros, contracturas.</li>
                                            <li><strong>Músculos rotadores del manguito rotador:</strong> Desgarros, tendinitis.</li>
                                        </ul>

                                </div>
                            </div>
                        </ng-template>
                    </li>   

                    <li ngbNavItem="ligamentos" class="nav-item  list-inline-item col-6 col-md-4">
                        <a ngbNavLink>
                            <div class="text-start py-1 px-2">
                                <h6 class="mb-0">Lesiones ligamentos</h6>
                            </div>
                        </a>
                        <ng-template ngbNavContent>
                            <div class="tab-pane fade p-4 rounded shadow active show" id="dental"
                                role="tabpanel" aria-labelledby="fisio">
                                <img src="assets/images/fisioterapia-bilbao/13_Fisioterapeuta_Bilbao_Lesion_ligamentos.webp" class="rounded shadow"  style="width:100%" loading="lazy" alt="Lesiones típicas en ligamentos"> 
                                <div class="mt-4">
                                    <h5>Lesiones típicas en ligamentos</h5>
                                    <p class="text-muted my-3">Nuestro fisioterapeuta en Bilbao tratará de forma personalizada tu lesión para ofrecerte una recuperación rápida y lograr el mejor nivel de recuperación física posible.</p>

                                    <h3>Lesiones de músculos que tratamos en la consulta de fisioterapia en Bilbao:</h3>
                                    <ul>
                                        <li><strong>Isquiotibiales:</strong> Desgarros, contracturas, tendinitis.</li>
                                        <li><strong>Cuádriceps:</strong> Desgarros, contracturas.</li>
                                        <li><strong>Gemelos:</strong> Desgarros, contracturas, tendinitis.</li>
                                        <li><strong>Músculos aductores de la cadera:</strong> Desgarros, contracturas.</li>
                                        <li><strong>Músculos rotadores del manguito rotador:</strong> Desgarros, tendinitis.</li>
                                    </ul>
                                </div>
                            </div>
                        </ng-template>
                    </li>   

                    <li ngbNavItem="tendones" class="nav-item  list-inline-item col-6 col-md-4">
                        <a ngbNavLink>
                            <div class="text-start py-1 px-2">
                                <h6 class="mb-0">Lesiones tendones</h6>
                            </div>
                        </a>
                        <ng-template ngbNavContent>
                            <div class="tab-pane fade p-4 rounded shadow active show" id="dental"
                                role="tabpanel" aria-labelledby="fisio">
                                <img src="assets/images/fisioterapia-bilbao/14_Fisioterapia_bilbao_lesion_tendon.webp" class="rounded shadow"  style="width:100%" loading="lazy" alt="Lesiones típicas en tendones"> 
                                <div class="mt-4">
                                    <h5>Lesiones típicas en tendones</h5>
                                    <p class="text-muted my-3">Nuestro fisioterapeuta en Bilbao hará un análisis personalizado de la lesión para proponerte un plan de recuperación adaptado a tu caso.</p>

                                    <h3>Lesiones de tendones que tratamos en la consulta de fisioterapia en Bilbao:</h3>
                                    <ul>
                                        <li><strong>Tendón de Aquiles:</strong> Tendinitis, rotura.</li>
                                        <li><strong>Tendón rotuliano:</strong> Tendinitis.</li>
                                        <li><strong>Tendones del manguito rotador:</strong> Tendinitis, roturas.</li>
                                        <li><strong>Tendón flexor del dedo pulgar:</strong> Tendinitis (tenosinovitis de De Quervain).</li>
                                        <li><strong>Tendón tibial posterior:</strong> Tendinitis.</li>
                                    </ul>                                     
                                </div>
                            </div>
                        </ng-template>
                    </li> 
                </ul>
                <div [ngbNavOutlet]="nav" class=" mt-2 col-md-12"></div>
            </div>
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!-- End -->
<!-- Start -->
<!--end section-->
<section class="section" style="padding-top: 20px ; padding-bottom:20px ;">
   
    <div class="container my-2">

        <h2 class="text-center">Nuestro <strong>Centro médico en Bilbao</strong> cuenta además con otras especialidades médicas</h2>

        <div class="row row-cols-2 row-cols-md-2 row-cols-lg-4  g-4">
          <div class="col">
            <div class="card h-100">
              <img src="assets/images/fisioterapia-bilbao/11_Link_Oftalmologia_bilbao.webp" class="card-img-top" alt="Oftalmología Bilbao" loading="lazy">
              <div class="card-body text-center">
                <h5 class="card-title"><a href="https://medicosbilbao.com/especialidades/oftalmologo-bilbao" target="_blank">Oftalmología Bilbao</a></h5>
                
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card h-100">
              <img src="assets/images/fisioterapia-bilbao/12_Link_Psicologia_bilbao.webp" class="card-img-top" alt="Psicología Bilbao" loading="lazy">
              <div class="card-body text-center">
                <h5 class="card-title"><a href="https://medicosbilbao.com/especialidades/psicologo-bilbao" target="_blank">Psicología Bilbao</a></h5>
                
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card h-100">
              <img src="assets/images/fisioterapia-bilbao/Portada_Logopeda_bilba.webp" class="card-img-top" alt="Logopedia Bilbao" loading="lazy">
              <div class="card-body text-center">
                <h5 class="card-title"><a href="https://medicosbilbao.com/especialidades/logopeda-bilbao" target="_blank">Logopedia Bilbao</a></h5>
                
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card h-100">
              <img src="assets/images/fisioterapia-bilbao/14_Link_nutrición_bilbao.webp" class="card-img-top" alt="Nutrición Bilbao" loading="lazy">
              <div class="card-body text-center">
                <h5 class="card-title"><a href="https://medicosbilbao.com/especialidades/nutricionista-bilbao" target="_blank">Nutrición Bilbao</a></h5>                
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
<!--end section-->
<!-- End -->
<!-- Start -->
<div class="container-fluid px-0">
    <div class="py-5 bg-footer">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-7">
                    <div class="section-title bg-white text-dark rounded border p-2 mb-3">
                        <div class="d-flex">
                            <a class="logo" routerLink="/index">
                                <img src="assets/images/00_Logo-Médicos-Bilbao-FISIOTERAPIA.png" class="l-dark logo-light-mode" class="img-fluid" alt="Nutricionista Bilbao" loading="lazy">        
                              </a>
                            <div class="flex-1 ms-md-4 ms-3">
                                <h4 class="fw-bold text-dark mb-1">Fisioterapia Bilbao</h4>
                                <p class="text-dark-50 mb-0">Creemos en un enfoque integral de la fisioterapia</p>
                            </div>
                        </div>
                    </div>
                </div>
                <!--end col-->

                <div class="col-md-5 mt-4 mt-sm-0">
                    <div class="text-md-end ms-5 ms-sm-0">
                        <a href="https://medicosbilbao.com" class="btn btn-primary me-2 me-lg-2 me-md-0 my-2"
                            data-bs-toggle="modal" data-bs-target="#appointment-request"><i
                                class="uil uil-file-alt"></i> Descubre más sobre Médicos Bilbao</a>
                    </div>
                </div>
                <!--end col-->
            </div>
            <!--end row-->
        </div>
        <!--end container-->
    </div>
    <!--end div-->
</div>

<!--modal contactar -->  
<div class="container">
	<div class="row g-0" >		
    <div class="nb-form">
    <p class="title"><strong>Pide tu cita aquí</strong></p>
    <img src="https://medicosbilbao.com/img/logocuadrado.png" alt="Cita Logopeda  Bilbao" class="user-icon" >
    <p class="message" ><b>Médicos Bilbao: </b>Infórmate sin compromiso. Puedes contactar con nosotros a través de estos enlaces </p>	
	<ul class="list-group">
  <li class="list-group-item"><i class="fab fa-whatsapp"></i> <a href="https://api.whatsapp.com/send?phone=+606573238&text=Hola,%20estoy%20interesado%20en%20pedir%20una%20cita%20...%20" target="_blank"> 606 57 32 38</a><br>(solo whatsapp) </li>
  <li class="list-group-item"><i class="fas fa-phone"></i> <a href="tel:+34944648357" style="text-decoration:none"> 944 648 357</a></li>
  <li class="list-group-item"><i class="fas fa-globe"></i><a href="https://www.doctoralia.es/clinicas/especialistas-medicos-bilbao" rel="nofollow" target="_blank" class="reserva"> Reservar cita</a></li>  
  <li class="list-group-item"><i class="fa fa-envelope" aria-hidden="true"></i> <a href="https://medicosbilbao.com/contactar" id="correo"> Correo eléctrónico</a></li>
  </ul>    
  </div>
	</div>
</div>	
<!-- FIN modal contactar -->  

<!--end container-->
<!-- End -->
<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->